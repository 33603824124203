(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("prop-types"), require("react"), require("classnames"), require("react-popper"), require("react-transition-group"), require("react-dom"), require("react-router-dom"), require("react-numeric-input"), require("outy"));
	else if(typeof define === 'function' && define.amd)
		define("mdbreact", ["prop-types", "react", "classnames", "react-popper", "react-transition-group", "react-dom", "react-router-dom", "react-numeric-input", "outy"], factory);
	else if(typeof exports === 'object')
		exports["mdbreact"] = factory(require("prop-types"), require("react"), require("classnames"), require("react-popper"), require("react-transition-group"), require("react-dom"), require("react-router-dom"), require("react-numeric-input"), require("outy"));
	else
		root["mdbreact"] = factory(root["PropTypes"], root["React"], root["classnames"], root["react-popper"], root["reactTransitionGroup"], root["ReactDOM"], root["react-router-dom"], root["react-numeric-input"], root["outy"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__13__) {
return 